.dropItem {
    width: 100px;
}

.space {
    padding-left: 5px
}

.name {
    width: 350px;
}
