.ganttTable {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
  border-right: #e6e4e4 1px solid;
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif, 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ganttTable_Header {
  display: table-row;
  list-style: none;

}

.ganttTable_HeaderSeparator {
  opacity: 1;
  margin-left: -2px;
}

.ganttTable_HeaderItem {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
}
